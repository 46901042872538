import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import LeadForm from "../lead-form";
import RoiCalculator from "../roi-calculator/pages/calc";
import LoanCalculator from "../loan-calculator/";
import LoanCalculatorV2 from "../loan-calculator-v2";
import LoanCalculatorV3 from "../questQoutePage/index";

const router = createBrowserRouter([
  {
    path: "/",
    Component: LeadForm,
  },
  {
    path: "/quote",
    Component: LeadForm,
  },
  {
    path: "/quote1",
    Component: LeadForm,
  },
  {
    path: "/roi-calculator",
    Component: RoiCalculator,
  },
  {
    path: "/loan-calculator",
    Component: LoanCalculator,
  },
  {
    path: "/loan-calculator-v2",
    Component: LoanCalculatorV2,
  },
  {
    path: "/quest-loan-calculator",
    Component: LoanCalculatorV3,
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
