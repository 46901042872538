import React from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  reportError,
  addBreadcrumb,
} from "../../../../../shared/error-monitoring";
import { preventChangeFocus } from "../../../../../shared/helpers";
import {
  RECAPTCHA_ACTION,
  MOBILE_VALIDATION_PATTERN,
  EMAIL_VALIDATION_PATTERN,
} from "../../../../constants/shared";
import {
  LEAD_SET_FIELDS,
  LEAD_SET_SUBMITTING,
  LEAD_SET_SUBMIT_ERROR,
  LEAD_SET_LEAD_ID,
  LEAD_SET_IS_OTP_STEP,
} from "../../../../reducers/actions";
import { submit } from "../../../../utils/api";
import { getRecaptchaToken } from "../../../../utils/recaptcha";
import LoadingIndicator from "../../../LoadingIndicator";
import _pick from "lodash.pick";

const PersonalInfoStep = () => {
  const leadFormFields = useSelector((state) => state.leadForm.fields);
  const isSubmitting = useSelector((state) => state.leadForm.isSubmitting);
  const submitError = useSelector((state) => state.leadForm.submitError);
  const currentStep = useSelector((state) => state.leadForm.currentStep);
  const isLoading = useSelector((state) => state.leadForm.stepLoading);
  const dispatch = useDispatch();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: _pick(leadFormFields, [
      "firstName",
      "lastName",
      "email",
      "mobile",
    ]),
  });

  const onSubmit = async (personalInfo) => {
    addBreadcrumb("submitLead", "Lead Data", leadFormFields);

    const leadFormData = {
      ...leadFormFields,
      ...personalInfo,
    };
    leadFormData.amount = parseFloat(leadFormFields.amount);

    dispatch({ type: LEAD_SET_FIELDS, payload: leadFormData });
    dispatch({ type: LEAD_SET_SUBMITTING, payload: true });
    dispatch({ type: LEAD_SET_SUBMIT_ERROR, payload: "" });

    try {
      const getToken = await getRecaptchaToken(RECAPTCHA_ACTION);

      if (!getToken.token) {
        reportError(getToken.error);
      }

      leadFormData.recaptchaToken = getToken.token;
      const submitToApi = await submit(leadFormData);

      dispatch({ type: LEAD_SET_LEAD_ID, payload: submitToApi.leadGuid });
      dispatch({ type: LEAD_SET_IS_OTP_STEP, payload: true });
    } catch (error) {
      dispatch({
        type: LEAD_SET_SUBMIT_ERROR,
        payload:
          "There's something wrong when submitting your request. Please try again.",
      });
      if (error instanceof Error) {
        reportError(error);
        return;
      }
      reportError(JSON.stringify(error));
    } finally {
      dispatch({ type: LEAD_SET_SUBMITTING, payload: false });
    }
  };

  return (
    <section
      className={`sqz-section ${
        currentStep === 9 && !isLoading ? "" : "sqz-hide"
      } sqz-last`}
      id="step-9"
      data-testid="form-step-9"
    >
      <div
        className="sqz-loader"
        style={{
          display: currentStep === 9 && isLoading ? "block" : "none",
        }}
        data-testid="loader"
      >
        <span className="sqz-loader_img_wrap">
          <img src="/assets/images/quest-loader.svg" alt="loader" />
          <p>
            <strong>Calculating...</strong>
          </p>
        </span>
      </div>
      <form className="container" onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 text-center">
            <div className="sqz-question_header">
              <h2>Good job!</h2>
              <h4>Got your quote – See it now</h4>
            </div>
            <img
              src="/assets/images/tick.svg"
              alt="tick"
              className="sqz-tick"
            />
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="text"
                    className="form-control required no-max-width"
                    data-testid="firstName"
                    placeholder="Full name"
                    id="sqz-field_10_1"
                    {...register("firstName", {
                      required: "Please fill out your full name",
                    })}
                  />
                  {errors?.firstName && (
                    <label className="error">{errors.firstName.message}</label>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="text"
                    className="form-control required no-max-width"
                    data-testid="lastName"
                    placeholder="Company name"
                    id="sqz-field_10_2"
                    {...register("lastName", {
                      required: "Please fill out your company name",
                    })}
                  />
                  {errors?.lastName && (
                    <label className="error">{errors.lastName.message}</label>
                  )}
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="email"
                    className="form-control required no-max-width"
                    data-testid="email"
                    placeholder="Email"
                    id="sqz-field_10_3"
                    {...register("email", {
                      required: "Please fill out your email address",
                      pattern: {
                        value: EMAIL_VALIDATION_PATTERN,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors?.email && (
                    <label className="error">{errors.email.message}</label>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <div className="sqz-form_row text-left">
                  <input
                    type="tel"
                    className="form-control required no-max-width"
                    data-testid="mobile"
                    placeholder="Mobile"
                    id="sqz-field_10_4"
                    maxLength={10}
                    {...register("mobile", {
                      required: "Please fill out your mobile phone number",
                      minLength: {
                        value: 10,
                        message:
                          "Invalid mobile number. Please use the following format: 04xxxxxxxx (e.g. 0412345678)",
                      },
                      pattern: {
                        value: MOBILE_VALIDATION_PATTERN,
                        message:
                          "Invalid mobile number. Please use the following format: 04xxxxxxxx (e.g. 0412345678)",
                      },
                    })}
                  />
                  {errors?.mobile && (
                    <label className="error">{errors.mobile.message}</label>
                  )}
                </div>
              </div>
            </div>
            {submitError && (
              <div>
                <label className="error">{submitError}</label>
                <br />
              </div>
            )}
            <div className="submit-button-row">
              <button
                type="submit"
                className="sqz-btn sqz-submit"
                data-testid="submit"
                disabled={isSubmitting || currentStep !== 9}
                onKeyDown={preventChangeFocus}
              >
                Get my Loan options
              </button>{" "}
              {isSubmitting && <LoadingIndicator className="loading-spinner" />}
            </div>
            <div className="sqz-form_row">
              <p>
                By proceeding you agree to Quest’s
                <br />{" "}
                <a href="https://quest.finance/help-support/privacy-policy/">
                  Privacy Policy
                </a>{" "}
                and{" "}
                <a href="https://quest.finance/help-support/terms-conditions/">
                  Terms & Conditions
                </a>
              </p>
            </div>
            <button
              type="button"
              className="sqz-back"
              onClick={() => {
                dispatch({ type: "CHANGE_STEP", payload: 8 });
              }}
              onKeyDown={preventChangeFocus}
              data-testid={`goBackToStep${currentStep - 1}`}
            >
              Prev
            </button>
            <div className="sqz-form_row">
              <p>Your details are protected</p>
              <img
                src="/assets/images/McAfee-Logo@2x.png"
                alt="McAfee"
                width="80"
              />
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default PersonalInfoStep;
