import * as Sentry from "@sentry/react";

export const initErrorMonitoring = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
  });
};

export const reportError = (err) => {
  Sentry.captureException(err);
};

export const addBreadcrumb = (category, message, data) => {
  Sentry.addBreadcrumb({
    category,
    message,
    data,
    level: "info",
  });
};
