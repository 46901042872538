import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CurrencyDisplay from "../shared/components/CurrencyDisplay";
import CurrencyInput from "../shared/components/CurrencyInput";
import RadioGroup from "../shared/components/RadioGroup";
import { MAXIMUM_LOAN_AMOUNT, MINIMUM_LOAN_AMOUNT } from "../shared/constants";
import { convertToCurrency, redirectParentWindow } from "../shared/helpers";
import calculateRepayment from "../shared/helpers/repayment-calculator";
import NiceSelect from "./components/NiceSelect";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const termOptions = [
  {
    label: "1 Year",
    value: 12,
  },
  {
    label: "2 Years",
    value: 24,
  },
  {
    label: "3 Years",
    value: 36,
  },
  {
    label: "4 Years",
    value: 48,
  },
  {
    label: "5 Years",
    value: 60,
  },
  {
    label: "6 Years",
    value: 72,
  },
  {
    label: "7 Years",
    value: 84,
  },
];
const repaymentFrequencyOptions = [
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Fortnightly",
    value: "fortnightly",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
];
const maxInterestRate = 16;
const minimumAmountCurrency = convertToCurrency(MINIMUM_LOAN_AMOUNT);
const maximumAmountCurrency = convertToCurrency(MAXIMUM_LOAN_AMOUNT);

export const otpStepSchema = yup.object().shape({
  loanAmount: yup.string().test({
    test(val, ctx) {
      const value = parseFloat(val);
      if (!value || value < MINIMUM_LOAN_AMOUNT) {
        return ctx.createError({
          message: `Minimum loan amount is ${minimumAmountCurrency}`,
        });
      } else if (value > MAXIMUM_LOAN_AMOUNT) {
        return ctx.createError({
          message: `Maximum loan amount is ${maximumAmountCurrency}`,
        });
      }

      return true;
    },
  }),
  interestRate: yup
    .string()
    .required("Interest rate is required")
    .test({
      test(val, ctx) {
        const value = parseFloat(val);
        if (!value || value < 1) {
          return ctx.createError({
            message: `Minimum interest rate is 1%`,
          });
        } else if (value > maxInterestRate) {
          return ctx.createError({
            message: `Maximum interest rate is ${maxInterestRate}%`,
          });
        }

        return true;
      },
    }),
  balloonRepayment: yup.string().test({
    test(val, ctx) {
      const value = parseFloat(val);
      if (val && value < 0) {
        return ctx.createError({
          message: "Minimum balloon repayment is 0%",
        });
      }

      return true;
    },
  }),
});

const getMaxBalloonRepayment = (loanTerm) => {
  if (loanTerm >= 60) {
    return 40;
  } else if (loanTerm === 48) {
    return 45;
  } else if (loanTerm === 36) {
    return 55;
  } else {
    return 65;
  }
};

const LoanCalculator = () => {
  const [repaymentEstimation, setRepaymentEstimation] = useState(0);
  const [url, setUrl] = useState(
    `${process.env.REACT_APP_BASE_URL}?source=webform_loan_calculcator`,
  );
  const {
    watch,
    control,
    register,
    setValue,
    formState,
    formState: { errors },
  } = useForm({
    defaultValues: {
      loanAmount: 50000,
      loanTerm: 60,
      interestRate: 5,
      balloonRepayment: 0,
      repaymentFrequency: "weekly",
    },
    resolver: yupResolver(otpStepSchema),
    mode: "onChange",
  });
  const formValues = watch();
  const {
    loanAmount,
    loanTerm,
    interestRate,
    balloonRepayment,
    repaymentFrequency,
  } = formValues;

  useEffect(() => {
    const captureQueryParams = async () => {
      let queryParams = window.location.search;

      if (queryParams) {
        queryParams = queryParams.replace("?", "");
        setUrl((prevState) => {
          return prevState + "&" + queryParams;
        });
      }
    };

    captureQueryParams();
  }, [setUrl]);

  useEffect(() => {
    const updatePaymentEstimation = async () => {
      if (formState.isValid) {
        setRepaymentEstimation(
          calculateRepayment(
            loanAmount,
            loanTerm,
            interestRate,
            balloonRepayment,
            repaymentFrequency,
          ),
        );
      }
    };

    updatePaymentEstimation();
  }, [
    loanAmount,
    loanTerm,
    interestRate,
    balloonRepayment,
    repaymentFrequency,
    formState.isValid,
  ]);

  useEffect(() => {
    if (loanAmount > MAXIMUM_LOAN_AMOUNT) {
      setValue("loanAmount", MAXIMUM_LOAN_AMOUNT);
    }
  }, [loanAmount, setValue]);

  useEffect(() => {
    if (interestRate > maxInterestRate) {
      setValue("interestRate", maxInterestRate);
    }
  }, [interestRate, setValue]);

  useEffect(() => {
    const maxBalloonRepayment = getMaxBalloonRepayment(loanTerm);
    if (balloonRepayment > maxBalloonRepayment) {
      setValue("balloonRepayment", maxBalloonRepayment);
    }
  }, [loanTerm, balloonRepayment, setValue]);

  return (
    <div className="sqz-calc_wrap text-center">
      <form id="sqz-calculator" action="/" noValidate>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="sqz-form_row">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>Loan Amount</label>
                    <div className="sqz-currency sqz-has_dollar">
                      <Controller
                        name="loanAmount"
                        control={control}
                        render={({ field }) => (
                          <CurrencyInput
                            value={field.value}
                            onValueChange={({ value }) => {
                              field.onChange(value);
                            }}
                            testId="amount"
                            className="form-control required"
                          />
                        )}
                      />
                      {errors.loanAmount && (
                        <label className="error" data-testid="loanAmountError">
                          {errors.loanAmount.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Loan Term</label>
                    <Controller
                      name="loanTerm"
                      control={control}
                      render={({ field }) => (
                        <NiceSelect
                          selected={field.value}
                          options={termOptions}
                          onChange={(e) =>
                            field.onChange(parseInt(e.target.dataset.value))
                          }
                          testId="loanTerm"
                          useScroll={true}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="sqz-form_row">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label>Interest rate</label>
                    <div className="sqz-has_percentage">
                      <input
                        maxLength={2}
                        inputMode="decimal"
                        className="form-control required"
                        data-testid="interestRate"
                        {...register("interestRate")}
                      />
                      {errors.interestRate && (
                        <label
                          className="error"
                          data-testid="interestRateError"
                        >
                          {errors.interestRate.message}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <label>Balloon Repayment</label>
                    <div className="sqz-has_percentage">
                      <input
                        inputMode="decimal"
                        maxLength={2}
                        className="form-control required"
                        data-testid="balloonRepayment"
                        {...register("balloonRepayment")}
                      />
                      {errors.balloonRepayment && (
                        <label
                          className="error"
                          data-testid="balloonRepaymentError"
                        >
                          {errors.balloonRepayment.message}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="sqz-form_row mb-0">
                <label>Repayment frequency</label>
                <Controller
                  name="repaymentFrequency"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup
                      name="repaymentFrequency"
                      options={repaymentFrequencyOptions}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="sqz-loan_box text-center">
                <div className="sqz-box_inner">
                  <img src="/assets/images/be-smiley.svg" alt="smiley" />
                  <p>Your estimated repayments are</p>
                  <p
                    className="sqz-loan_amount"
                    data-testid="repaymentsEstimation"
                  >
                    <CurrencyDisplay
                      value={repaymentEstimation}
                      rounding={true}
                    />
                  </p>
                  <p>{repaymentFrequency}</p>
                  <button
                    type="button"
                    className="sqz-btn"
                    onClick={() => redirectParentWindow(url)}
                    data-testid="redirectParentButton"
                  >
                    Get a personalised quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoanCalculator;
