import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import RadioGroup from "../../../../../shared/components/RadioGroup";
import { reportError } from "../../../../../shared/error-monitoring";
import { preventChangeFocus } from "../../../../../shared/helpers";
import { OTP_ERROR_MESSAGES } from "../../../../constants/errors";
import {
  LEAD_SET_STEP_LOADING,
  LEAD_SET_LEAD_ID,
} from "../../../../reducers/actions";
import { resendOtp, verifyOtp } from "../../../../utils/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { otpStepSchema } from "../../../../constants/validations";
import { receivedOptions } from "../../../../constants/options";
import _pick from "lodash.pick";

const OtpStep = ({ handleResults }) => {
  const isLoading = useSelector((state) => state.leadForm.stepLoading);
  const leadFormFields = useSelector((state) => state.leadForm.fields);
  const leadGuid = useSelector((state) => state.leadForm.leadGuid);
  const amount = useSelector((state) => state.leadForm.fields.amount);
  const dispatch = useDispatch();

  const [isResent, setIsResent] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(otpStepSchema),
    defaultValues: _pick(leadFormFields, ["mobile"]),
  });
  const formFields = watch();

  useEffect(() => {
    clearErrors();
  }, [formFields.isOtpReceived, clearErrors]);

  const resendPin = async (formData) => {
    dispatch({
      type: LEAD_SET_STEP_LOADING,
      payload: true,
    });

    clearErrors();

    try {
      const resend = await resendOtp({
        mobile: formData.mobile,
        leadGuid,
      });

      dispatch({
        type: LEAD_SET_LEAD_ID,
        payload: resend.leadGuid,
      });

      setValue("isOtpReceived", "");
      setIsResent(true);
    } catch (error) {
      setError("mobile", {
        type: "manual",
        message:
          "There's something wrong when submitting your request. Please try again.",
      });
      reportError(error);
    } finally {
      dispatch({
        type: LEAD_SET_STEP_LOADING,
        payload: false,
      });
    }
  };

  const submitCode = async (formData) => {
    dispatch({
      type: LEAD_SET_STEP_LOADING,
      payload: true,
    });

    clearErrors();

    try {
      const results = await verifyOtp({
        leadGuid,
        otpCode: formData.otpCode,
      });

      handleResults(results.products, parseFloat(amount));
    } catch (error) {
      let message =
        "There's something wrong when submitting your request. Please try again.";
      if (error.errorCode) {
        message = OTP_ERROR_MESSAGES[error.errorCode];
      }

      setError("otpCode", {
        type: "manual",
        message,
      });

      if (error instanceof Error) {
        reportError(error);
        return;
      }
    } finally {
      dispatch({
        type: LEAD_SET_STEP_LOADING,
        payload: false,
      });
    }
  };

  const onSubmit = (formData) => {
    if (formData.isOtpReceived === "YES") {
      submitCode(formData);
    } else {
      resendPin(formData);
    }
  };

  return (
    <section
      className={`sqz-section ${isLoading ? "sqz-hide" : ""}`}
      id="step-otp"
      data-testid="form-step-otp"
    >
      <img
        src="/assets/images/typing-bubble.svg"
        alt="typing-bubble"
        className="sqz-typing_bubble"
      />
      <form className="container" onSubmit={handleSubmit(onSubmit)}>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-md-10 text-center">
            <div className="sqz-question_header mb-4">
              <h2>Almost there!</h2>
              <p>
                We&apos;ve just {isResent ? "resent" : "sent"} a secure code to
                your mobile. <br /> Did you receive it?
              </p>
            </div>
            <div className="sqz-form_row">
              <Controller
                name="isOtpReceived"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    onChange={(e) => field.onChange(e.target.value)}
                    options={receivedOptions}
                    center={true}
                    {...field}
                    checked={field.value}
                  />
                )}
              />
            </div>
            {formFields.isOtpReceived === "NO" && (
              <div className="sqz-form_row">
                <p className="big">
                  Uh-oh. Please enter your mobile number and we&apos;ll resend
                  it.
                </p>
                <div className="sqz-middle-form">
                  <input
                    type="tel"
                    className="form-control required no-max-width"
                    data-testid="mobileConfirmation"
                    id="sqz-field_10_4"
                    maxLength={10}
                    {...register("mobile")}
                  />
                </div>
                {errors.mobile && (
                  <label className="error">{errors.mobile.message}</label>
                )}
              </div>
            )}
            {formFields.isOtpReceived === "YES" && (
              <div className="sqz-form_row">
                <p className="big">Great! Please enter the secure code here</p>
                <div className="sqz-middle-form">
                  <input
                    type="tel"
                    className="form-control required no-max-width"
                    data-testid="otpCode"
                    id="sqz-field_10_4"
                    maxLength={10}
                    {...register("otpCode")}
                  />
                </div>
                {errors.otpCode && (
                  <label className="error">{errors.otpCode.message}</label>
                )}
              </div>
            )}
            {formFields.isOtpReceived && (
              <div className="text-center">
                <button
                  type="submit"
                  className="sqz-btn sqz-next mt-4"
                  data-testid="submitButton"
                  onKeyDown={preventChangeFocus}
                  disabled={isLoading}
                >
                  {formFields.isOtpReceived === "YES"
                    ? "CONTINUE"
                    : "RESEND CODE"}
                </button>
              </div>
            )}
          </div>
        </div>
      </form>
    </section>
  );
};

OtpStep.propTypes = {
  handleResults: PropTypes.func,
};

export default OtpStep;
