import React from "react";
import PropTypes from "prop-types";
import {NumericFormat} from "react-number-format";
import { roundAccurately } from "../helpers";

const CurrencyDisplay = ({ value, rounding, prefix }) => {
  if (typeof prefix === "undefined") prefix = "$";
  if (!value) return `${prefix}0`;
  if (rounding) value = roundAccurately(value, 2);
  return (
    <NumericFormat
      prefix={prefix}
      value={value.toString()}
      thousandSeparator=","
      decimalScale={2}
      displayType={"text"}
    />
  );
};

CurrencyDisplay.propTypes = {
  value: PropTypes.any,
  rounding: PropTypes.bool,
  prefix: PropTypes.string,
};

export default CurrencyDisplay;
