import React from "react";
import PropTypes from "prop-types";
import {NumericFormat} from "react-number-format";

/*
    The 'onChange' prop will not pass an event object. Instead, it will pass an object containing value, formattedValue, and floatValue. Example:
    {
        formattedValue: "5,000.00"
        floatValue: 5000
        value: "5000"
    }
*/

const CurrencyInput = ({
  name,
  value,
  onValueChange,
  onKeyDown,
  className,
  inputRef,
  testId,
}) => {
  return (
    <NumericFormat
      name={name}
      value={value}
      allowNegative={false}
      thousandSeparator=","
      decimalScale={2}
      inputMode="decimal"
      displayType="input"
      className={className}
      onValueChange={onValueChange}
      getInputRef={inputRef}
      data-testid={testId}
      onKeyDown={onKeyDown}
    />
  );
};

CurrencyInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onValueChange: PropTypes.func,
  className: PropTypes.string,
  inputRef: PropTypes.object,
  testId: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default CurrencyInput;
