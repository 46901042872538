import * as yup from "yup";
import { MOBILE_VALIDATION_PATTERN } from "./shared";

const invalidMobileNumFormatMsg =
  "Invalid mobile number. Please use the following format: 04xxxxxxxx (e.g. 0412345678)";

export const otpStepSchema = yup.object().shape({
  isOtpReceived: yup.string(),
  mobile: yup.string().when("isOtpReceived", {
    is: "NO",
    then: (schema) =>
      schema
        .length(10, invalidMobileNumFormatMsg)
        .matches(MOBILE_VALIDATION_PATTERN, invalidMobileNumFormatMsg)
        .required("Please fill out your mobile phone number"),
    otherwise: (schema) => schema.optional(),
  }),
  otpCode: yup.string().when("isOtpReceived", {
    is: "YES",
    then: (schema) =>
      schema.required("Please enter the secure code we've sent to your mobile"),
    otherwise: (schema) => schema.optional(),
  }),
});
