import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  captureQueryParams,
  convertSnakeToCamel,
} from "../../../shared/helpers";
import {
  purposeOptions,
  periodOptions,
  businessAgeMonthOptions,
  creditHistoryOptions,
  monthlyTurnoverOptions,
  neededByOptions,
} from "../../constants/options";
import {
  LEAD_SET_FIELDS,
  LEAD_SET_STEP_LOADING,
  LEAD_SET_PREFILL_FIELD,
} from "../../reducers/actions";
import { colorScheme } from "../../types";
import { loadRecaptcha } from "../../utils/recaptcha";
import Form from "../Form";
import AmountStep from "./steps/AmountStep";
import GoalsStep from "./steps/GoalsStep";
import OtpStep from "./steps/OtpStep";
import PersonalInfoStep from "./steps/PersonalInfoStep";
import SelectionStep from "./steps/SelectionStep";

const QuoteForm = ({ handleResults, colorScheme }) => {
  const dispatch = useDispatch();

  const currentStep = useSelector((state) => state.leadForm.currentStep);
  const stepsCount = useSelector((state) => state.leadForm.stepsCount);
  const formStyles = useSelector((state) => state.leadForm.formStyles);
  const isOtpStep = useSelector((state) => state.leadForm.isOtpStep);
  const formErrors = useSelector((state) => state.leadForm.formErrors);

  const isFirstComponentLoad = useRef(true);
  const stepsToAdvance = useRef(0);
  const previousStep = useRef(1);

  const navigate = useNavigate();

  useEffect(() => {
    loadRecaptcha();
  }, []);

  useEffect(() => {
    const setFieldsFromParams = async () => {
      const fieldsToGet = [
        "amount",
        "purpose",
        "source",
        "utm_source",
        "utm_campaign",
        "utm_medium",
        "utm_term",
        "utm_content",
        "ref",
        "variant",
      ];

      const tempFields = captureQueryParams(
        window.location.search,
        fieldsToGet,
      );

      for (const field in tempFields) {
        const fieldName = convertSnakeToCamel(field);
        const fieldValue = tempFields[field];
        tempFields[fieldName] = fieldValue;
        if (fieldName !== field) delete tempFields[field];
      }

      tempFields.httpReferrer = document.referrer;

      await dispatch({ type: LEAD_SET_FIELDS, payload: tempFields });

      if (tempFields.amount) {
        dispatch({
          type: LEAD_SET_PREFILL_FIELD,
          payload: {
            fieldName: "amount",
            fieldValue: tempFields.amount,
          },
        });
        stepsToAdvance.current += 1;
      }

      if (tempFields.purpose) {
        stepsToAdvance.current += 1;
      }
    };

    setFieldsFromParams();
  }, [dispatch]);

  useEffect(() => {
    let loadingTime = 1000;
    const steps = [
      "amount",
      "purpose",
      "goals",
      "period",
      "business-age",
      "credit-history",
      "monthly-turnover",
      "needed-by",
      "quote",
      "verify",
    ];

    let stepIndex = currentStep - 1;
    if (isOtpStep) {
      stepIndex = steps.indexOf("verify");
    }

    if (currentStep === 9) loadingTime = 5000;
    const timeout = setTimeout(() => {
      dispatch({ type: LEAD_SET_STEP_LOADING, payload: false });
    }, loadingTime);

    const isQueryParamEmpty = window.location.search === "";
    const isStepUndefined = typeof steps[stepIndex] === "undefined";

    if (
      (!isFirstComponentLoad.current || isQueryParamEmpty) &&
      stepsToAdvance.current === 0 &&
      !isStepUndefined
    ) {
      navigate(`#${steps[stepIndex]}`);
    }

    window.scrollTo(0, 0);

    if (formErrors !== null) {
      stepsToAdvance.current -= 1;
      dispatch({
        type: LEAD_SET_PREFILL_FIELD,
        payload: {
          fieldName: "",
          fieldValue: "",
        },
      });
    }

    if (stepsToAdvance.current > 0) {
      stepsToAdvance.current -= 1;
    }

    isFirstComponentLoad.current = false;

    previousStep.current = currentStep;

    return function cleanup() {
      clearTimeout(timeout);
    };
  }, [currentStep, navigate, isOtpStep, formErrors, dispatch]);

  return (
    <Form
      stepsCount={stepsCount}
      currentStep={currentStep}
      formStyles={formStyles}
      colorScheme={colorScheme}
    >
      <React.Fragment>
        <AmountStep />
        <SelectionStep
          stepNumber={2}
          previousStep={1}
          nextStep={3}
          stepName="purpose"
          options={purposeOptions}
          header={<h2>What do you need the money for?</h2>}
        />
        <GoalsStep />
        <SelectionStep
          stepNumber={4}
          previousStep={3}
          nextStep={5}
          stepName="period"
          options={periodOptions}
          header={<h2>How long do you need the loan for?</h2>}
        />
        <SelectionStep
          stepNumber={5}
          previousStep={4}
          nextStep={6}
          stepName="businessAgeMonth"
          options={businessAgeMonthOptions}
          header={<h2>How long have you been in business?</h2>}
        />
        <SelectionStep
          stepNumber={6}
          previousStep={5}
          nextStep={7}
          stepName="creditHistory"
          options={creditHistoryOptions}
          header={
            <h2>
              What’s your credit history like?
              <small>
                We look at your overall strength, not just your credit history
              </small>
            </h2>
          }
        />
        <SelectionStep
          stepNumber={7}
          previousStep={6}
          nextStep={8}
          stepName="monthlyTurnover"
          options={monthlyTurnoverOptions}
          header={<h2>What&apos;s your average monthly turnover?</h2>}
        />
        <SelectionStep
          stepNumber={8}
          previousStep={7}
          nextStep={9}
          stepName="neededBy"
          options={neededByOptions}
          header={<h2>When do you need the money?</h2>}
        />
        {isOtpStep ? (
          <OtpStep handleResults={handleResults} />
        ) : (
          <PersonalInfoStep />
        )}
      </React.Fragment>
    </Form>
  );
};

QuoteForm.propTypes = {
  handleResults: PropTypes.func,
  referrer: PropTypes.string,
  colorScheme,
};

export default QuoteForm;
